<template>
  <div class="container">
    <div class="title">{{ detailData.Title }}</div>
    <div class="time">
      {{ time }}
    </div>
    <div v-if="type == 1" style="width: 100%; height: 241px;">
        <video
          id="player-container-id"
          style="width: 100%; height: 100%;"
          preload="auto"
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
        ></video>
    </div>

    <div class="swipe" v-else>
      <img class="leftArrow" v-if="detailData.lst.length > 1" src="@/assets/images/back_comm_icon@2x.png" @click="prev" />
      <van-swipe ref="swipe" class="my-swipe" indicator-color="#CA001B" :height="height" @change="onChange">
        <van-swipe-item v-for="(item, index) in detailData.lst" :key="index">
          <img class="bannerImg" :src="item.Url" @click="imgPreview(index)" />
        </van-swipe-item>
      </van-swipe>
      <img class="rightArrow" v-if="detailData.lst.length > 1" src="@/assets/images/back_comm_icon@2x.png" @click="next" />
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: 'WorksDetail',
  data() {
    return {
      detailData: '',
      time: '',
      height: 0
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    type() {
      return this.$route.query.type;
    }
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async getData() {
      let res = await this.$axios.get('/Api/api/Web/Works/GetWF?wkid=' + this.id);
      this.detailData = res.Data;
      this.time = res.Data.CreateTime.slice(0,10);
      if(this.type == 1 && this.detailData.lst.length > 0) {
        this.initVideo();
      }

      this.getImgSize(this.detailData.lst[0].Url).then((res) => {
        this.height=res.height/(res.width/375)
      })
    },
    // 初始化播放器
    initVideo() {
      this.player = this.TCPlayer("player-container-id", {
        playbackRates: [0.5, 1, 1.5, 2],
        licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
      }).player;
      this.player.src(this.detailData.lst[0].Url);
    },
    // 通过图片的url获取图片尺寸
    getImgSize (url) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = url
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height
          })
        }
      })
    },
    //swipe的切换监听事件
    async onChange(index) {
      let res= await this.getImgSize(this.detailData.lst[index].Url);
      this.height=res.height/(res.width/375);
    },
    imgPreview(index) {
      let imgList = [];
      this.detailData.lst.forEach(item => {
        imgList.push(item.Url);
      })
      ImagePreview({
        images: imgList,
        startPosition: index,
      });
    },
    prev() {
      this.$refs.swipe.prev()
    },
    next() {
      this.$refs.swipe.next()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px 20px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .time {
    font-size: 12px;
    color: #adadad;
    margin: 10px 0 20px;
  }
  .swipe {
    position: relative;
    display: flex;
    align-items: center;
  }
  .leftArrow {
    position: absolute;
    left: 10px;
    width: 30px;
    height: 30px;
    z-index: 99;
  }
  .rightArrow {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 99;
    transform: rotate(180deg);
  }
  .bannerImg {
    width: 100%;
  }
}

/deep/ .van-swipe__indicators {
  bottom: 10px !important;
}
</style>